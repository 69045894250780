@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;1,900&display=swap");
:root {
  --text-dark: #000;
  --text-white: #fff;
  --text-grey: #adbdcc;
  --body-font: "Source Sans Pro", "sans-serif";
  --main-bg-color: #e3dffd;
  --second-bg-color: #ecf2ff;
  --h1-size: 42px;
  --h2-size: 32px;
  --h3-size: 28px;
  --h4-size: 22px;
  --h5-size: 20px;
  --h6-size: 16px;
  --purple-1: #6962f7;
  --purple-2: #7000ff;
}
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-size: 62.5%;
}
body {
  background-color: var(--second-bg-color);
  color: var(--text-dark);
  padding: 5px;
  font-family: var(--body-font);
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-size: var(--h1-size);
  font-weight: 600;
  letter-spacing: -1px;
}
h2 {
  font-size: var(--h2-size);
  margin-top: 3px;
  font-weight: 600;
}

h3 {
  font-size: var(--h3-size);
}
p {
  font-size: var(--h4-size);
}
a {
  text-decoration: none;
  color: var(--purple-1);
  font-weight: 500;
  transition: color 0.25s ease;
}
a:hover {
  color: var(--purple-2);
}
.error {
  color: orangered;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: "bold";
}
.success {
  color: yellowgreen;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: "bold";
}
.nav-dropdown {
  font-size: 1.5rem;
  padding: 1rem;
}
