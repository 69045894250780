.mainDiv {
  text-align: center;
}
.imgContainer {
  height: 400px;
  width: 100%;
}
.imgContainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.imgContainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .imgContainer img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .mainDiv {
    margin-top: 5rem;
  }
}
