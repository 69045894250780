.cardContainer {
  position: relative;
  height: 50rem;
  width: 30rem;
  background-size: cover;
  border-radius: 1rem;
  box-shadow: 5px 0 #6962f7;
  /* background-image: url(../../../public/images/astro.avif); */
  transition: all 1s;
  border-bottom: 1px solid black;
}
.cardContainer:hover {
  transform: scale(1.05);
}
.cardContainer .imageDiv {
  width: 100%;
  height: 100%;
}
.cardContainer .imageDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cardTextContain {
  text-align: center;
  padding-top: 5rem;
  min-height: 60%;
  width: 100%;
  /* background-color: azure; */
  position: absolute;
  bottom: 0;
  clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 100%);
  background-image: linear-gradient(
    45deg,
    rgba(227, 223, 253, 0.5),
    rgba(236, 242, 255, 0.5)
  );
}
.cardContainer p {
  font-style: italic;
  font-weight: 700;
}
/* media queries */

@media only screen and (max-width: 1130px) {
  .cardContainer {
    margin-bottom: 2rem;
    margin-left: 2rem;
  }
}
