.loader span {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: greenyellow;
  margin: 0 0.5rem;
  animation: 1s jump infinite;
}
.loader span:nth-child(1) {
  animation: 1s jump infinite ease-in-out;
}
.loader span:nth-child(2) {
  animation: 1s 0.333s jump infinite ease-in-out;
}
.loader span:nth-child(3) {
  animation: 1s 0.666s jump infinite ease-in-out;
}
.loader span:nth-child(4) {
  animation: 1s 0.999s jump infinite ease-in-out;
}
@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2rem);
  }
}
