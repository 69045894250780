.form {
  margin: 0 auto;
  height: 90vh;
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.form-div {
  width: 100%;
}

.formButton {
  margin-left: 0.5rem;
  font-size: 1.5rem;
  min-width: 20vw;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
}
.custom-btn:hover {
  background-color: var(--purple-1);
  transition: 1s;
}

input + p {
  color: orangered;
}
.control-group {
  text-align: left;
  width: 100%;
  position: relative;
}
i {
  position: absolute;
  top: 5rem;
  right: 2rem;
}
.fa,
.fa-eye,
.fa-eye-slash {
  font-size: 20px; /* Adjust the value as needed */
}
