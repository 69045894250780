.priceContainer {
  padding: 5rem 1rem;
}
.priceContainer h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.priceCardContainer {
  padding: 0 5rem;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.priceContainer p {
  margin-top: 2rem;
  text-align: center;
}
.priceContainer .price-warning {
  color: yellowgreen;
  background-color: black;
  background-clip: content-box;
}
