footer {
  margin-top: -0.5rem;
  height: 30rem;
  background-color: var(--main-bg-color);
}
footer .imageContainer {
  text-align: center;
  margin-bottom: 2rem;
  padding-top: 2rem;
}
footer .imageContainer img {
  width: 10rem;
}
footer .footerFlexContainer {
  display: flex;
  justify-content: space-between;
}
.footerFirstContainer ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
}
.footerFirstContainer ul li {
  display: inline-block;
  margin-left: 1rem;
}
.footerFirstContainer ul li a:link,
:visited {
  display: inline-block;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #000;
  transition: all 1s ease-out;
}
.footerFirstContainer ul li a:hover {
  transform: scale(1.2);
  background-color: #7000ff;
  box-shadow: 1px 1px;
  color: white;
  transition: all 1s ease-out;
}
.footerLine {
  border-top: 2px solid black;
  padding-top: 2rem;
}
.footerSecondContainer {
  width: 30%;
}
.copyright {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 4rem;
}
@media only screen and (max-width: 765px) {
  .footerFirstContainer ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .location {
    font-size: var(--h5-size);
    text-align: center;
  }
}
